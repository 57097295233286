import request from '@/utils/request'

// 查询图库信息分页
export function pagePicture(query) {
  return request({
    url: '/tl/tl-picture/page',
    method: 'get',
    params: query
  })
}

// 查询图库信息详细
export function getPicture(data) {
  return request({
    url: '/tl/tl-picture/detail',
    method: 'get',
    params: data
  })
}

// 新增图库信息
export function addPicture(data) {
  return request({
    url: '/tl/tl-picture/add',
    method: 'post',
    data: data
  })
}

// 修改图库信息
export function updatePicture(data) {
  return request({
    url: '/tl/tl-picture/edit',
    method: 'post',
    data: data
  })
}

// 删除图库信息
export function delPicture(data) {
  return request({
    url: '/tl/tl-picture/delete',
    method: 'post',
    data: data
  })
}
