<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="图库名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="设备型号" prop="deviceModelIds">
        <a-select
          v-model="form.dtIds"
          mode="multiple"
          placeholder="请选择"
          style="width: 240px;"
          @change="handleChange">
          <a-select-option v-for="(item,key) in deviceTypeList" :key="key" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="图片" prop="picPath">
        <!-- <div class="ant-upload-preview" @click="$refs.upImg.edit(form.picPathStr)" >
          <div class="mask" :style="form.picPathStr ? 'opacity: 0' : 'opacity: 1'">
            <a-icon type="plus" />
          </div>
          <img :src="form.picPathStr" v-if="form.picPathStr" style="width: 100px;height: 100px"/>
        </div> -->
        <a-upload
          action="https://pc.tlxone.com/admin-api/common/local/file/upload"
          :headers="header"
          accept=".png,.jpg,.jpeg"
          :showUploadList="false"
          @change="handleChangeImg"
        >
          <div class="ant-upload-preview">
            <div class="mask" :style="form.picPath ? 'opacity: 0' : 'opacity: 1'">
              <a-icon type="plus" />
            </div>
            <img :src="form.picPath" v-if="form.picPath" style="width: 100px;height: 100px"/>
          </div>
        </a-upload>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <up-img-modal ref="upImg" :width="240" :height="400" @ok="setImg"/>
  </a-drawer>
</template>

<script>
import { pageDeviceType } from '@/api/valve/deviceType'
import store from '@/store'
import { getPicture, addPicture, updatePicture } from '@/api/valve/gallery'
import upImgModal from '@/components/upImgModal'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    upImgModal
  },
  data () {
    return {
      header: { Authorization: 'Bearer ' + store.getters.token },
      submitLoading: false,
      formTitle: '',
      deviceTypeList: [],
      // 表单参数
      form: {
        id: 0,
        name: null,
        picPath: null,
        status: 0,
        dtIds: [],
        deviceModelIds: ''
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [{ required: true, message: '型号名称不能为空', trigger: 'blur' }],
        deviceModelIds: [
          { required: true, message: '请选择设备型号', trigger: ['blur', 'change'] }
        ],
        picPath: [{ required: true, message: '图片不能为空', trigger: ['blur', 'change'] }]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    handleChange(e) {
      console.log(e)
      this.form.deviceModelIds = e.toString()
    },
    // 上传成功
    handleChangeImg (info) {
      console.log(info.file.status, info.file)
      if (info.file.status !== 'uploading') {
        this.uploadLoading = true
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        console.log(info.file.status, info.file.response, info.file)
        // this.$message.success(`${info.file.name} 上传成功`)
        if (info.file.response.code === '20001' || info.file.response.code === 20001) {
          console.log(info.file.response.data)
          this.form.picPath = info.file.response.data
          // this.form.picPathStr = info.file.response.data
          this.uploadLoading = false
        } else {
          this.$message.error(`上传失败 ${info.file.response.message}`)
          this.uploadLoading = false
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`)
        this.uploadLoading = false
      }
    },
    setImg (url) {
      console.log(url)
      this.form.picPath = url
      // this.form.picPathStr = url
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: 0,
        name: null,
        picPath: null,
        status: 0,
        dtIds: [],
        deviceModelIds: ''
      }
      pageDeviceType({ type: 0, pageSize: 999999999, sortField: 'createTime', sortOrder: 'DESCEND' }).then(response => {
        this.deviceTypeList = response.data.records
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getPicture({ 'id': id }).then(response => {
        const info = response.data
        info.dtIds = info.deviceModelIds ? info.deviceModelIds.split(',') : []
        this.form = info
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log(this.form)
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id) {
            updatePicture(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addPicture(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .ant-upload-preview {
    position: relative;
    width: 100px;
    height: 100px;
    .upload-icon {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 1.4rem;
      padding: 0.5rem;
      background: rgba(222, 221, 221, 0.7);
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .mask {
      position: absolute;
      background: rgba(0,0,0,0.4);
      cursor: pointer;
      transition: opacity 0.4s;
      &:hover {
        opacity: 1;
      }
      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1rem;
        margin-top: -1rem;
        color: #d6d6d6;
      }
    }
    img, .mask {
      width: 100%;
      max-width: 180px;
      height: 100%;
      overflow: hidden;
      object-fit: contain;
    }
  }
</style>
